@import '../../../../styles/variables.scss';

.ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-header {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.31);
  padding: 12px 24px;
}

.notification-card {
  padding: 10px 20px;
  margin: 10;
  padding: 5;
  display: flex;

  &:first-child {
    margin-top: 5px;
  }

  .content-text {
    font-weight: 600;
    font-size: 13px;
    line-height: 1.3;
  }

  .date-text {
    padding: 3px 0px 0px 0px;
    font-size: 12px;
  }
}

.white-bg {
  background-color: $white;
}

.gray-bg {
  background-color: $gradient3;
}

.notification-text {
  font-weight: 700;
  font-size: 18px;
  width: fit-content;
  line-height: 0.6;
  display: flex;
  align-items: center;
  opacity: 0.85;
}

.notification-header {
  justify-content: space-between;
  align-items: center;
}

.notification-header-container {
  display: flex;

  > div {
    width: 100%;
  }

  .image-container {
    display: flex;
    justify-content: flex-end;
  }

  .close-icon-size {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

.notification-no-data {
  height: 100%;
  align-items: center;
  justify-content: center;

  .header {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }

  .subHeader {
    text-align: center;
    margin: 5px;
  }
}

.notification-msg {
  font-weight: 500;
  font-size: small;
}
