@import '../../../../styles/variables.scss';

.ant-layout-sider {
  &.ant-layout-sider-light {
    background-color: $transparent;

    .ant-menu {
      &.ant-menu-light {
        padding: 8px 0px;
        border-inline-end: 0px !important;
        background-color: $transparent;

        .ant-menu-item {
          font-weight: 500;
        }

        .ant-menu-title-content {
          font-weight: 500;
          justify-content: left;
          margin-inline-start: 15px;
        }

        .ant-menu-item-only-child {
          padding-left: 5px !important;
        }
      }
    }
  }
}
