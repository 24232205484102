@import '../../../../styles/variables.scss';

.ant-layout-header {
  background-color: $transparent !important;
  border-bottom: solid 1px lightGray;
  padding-inline: 32px !important;
}

.trigger {
  margin-right: 20px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

// .ant-space-item {
//   margin-bottom: -14px !important;
// }
.avatar {
  margin-right: 12px;
  margin-bottom: 7px;
}
.header-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  margin: -8px 0px 8px 8px;
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.app-name {
  font-size: 16px;
  margin-top: 4px;
  font-weight: 500;
}

.bell-outlined {
  font-size: 20px;
  margin-top: 15px;
  margin-right: 20px;
}

.bell-outlinedIcon {
  text-align: center;
}
