$transparent: rgba(0, 0, 0, 0);
$gradient1: #d9e5fd;
$gradient2: #f2f7fc;
$gradient3: #eaedf1;
$success_color: #80c42a;
$error_color: #ee989a;
$white: #ffffff;
$blue: #1677ff;
$lightgray: #d3d3d3;
$lightblack: #0d0d0d4f;
