@import '../../../../../styles/variables.scss';

.ant-message {
  .ant-message-notice-content {
    padding: 0;
  }
  .ant-message-custom-content.ant-message-error {
    background-color: $error_color !important;
    color: $white;
    padding: 10px 16px;
    font: 500 14px;
  }
}

.ant-message {
  .ant-message-custom-content.ant-message-success {
    background-color: $success_color !important;
    color: $white;
    padding: 10px 16px;
    font: 500 14px;
    max-width: 360px !important;
  }
}

.ant-message-notice-content {
  padding: 0px;
  margin-left: auto;
}

.custom-message-area {
  text-align: left;
  padding-left: 26px;
  padding-top: 8px;
}

.toast-area {
  height: max-content;
  margin-left: auto !important;
  display: flex;
}

.message-header {
  text-align: left;
  display: flex;
}

.message-icon {
  color: $white;
}

.message-label {
  font-weight: 700;
}
