@import '../../../../../styles/variables.scss';

.loader-main-container {
  width: 100%;
  justify-content: center;
  position: fixed !important;
  height: 100vh !important;
  z-index: 2000 !important;
  background: $lightblack;
}
