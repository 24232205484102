@import '../../../../styles/variables.scss';

.logged-in-layout {
  &.ant-layout {
    min-height: 100vh;
  }

  .ant-layout-sider {
    min-height: 100%;
    padding: 0 10px;
    background-color: $transparent;
    height: 100%;
    overflow: auto;
  }
}

.ant-layout {
  background-color: $transparent;
}

.ant-main-layout {
  background-color: $transparent;
}

.main-layout {
  background-image: linear-gradient(145deg, $gradient1, $gradient2, $gradient1);
}

.common-background {
  background-color: $white;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  height: fit-content;
}

.content {
  display: flex;
  flex: 1;
  padding: 32px;
  height: 100%;
  width: 100%;
}

.ant-layout-sider-trigger {
  display: none;
}

.main-layout {
  height: 100vh;
}

.content-layout {
  height: 100%;
  overflow-y: auto;
}

.sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-container {
  margin: 0;
}
